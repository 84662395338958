import { Box, Icon, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import Image from "../../assets/icons/Image.png";
import Title from "../typographyUI/Title";
import Lead from "../typographyUI/Lead";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const UpliadImageFieldWithoutFormik = ({
  defaultValue,
  onChange: _onChange,
  defaultImg,
  sx={},
  uploadImg = false,
  maxSize = 5 * 1024 * 1024, //30 mb
}) => {
  let fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(defaultValue || "");
  const onChange = (e) => {
    const file = e.target.files[0];
    if (file.size <= maxSize) {
      setFile(file);
      _onChange(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        const imageUrl = `data:${file.type};base64,${base64String}`;
        setImg(imageUrl);
      };

      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds the 5MB limit. Please choose a smaller file.");
      e.target.value = null;
    }
  };
  const outletData = useSelector((state) => state.auth.user);
  return (
    <Stack
      alignItems={"center"}
      spacing={"10px"}
      sx={{
        maxWidth: "200px",
        position: "relative",
      }}
    >
      {/* <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
        onClick={() => {
          setImg("");
          fileRef.current = null;
          
          setFile(null);
        }}
      >
        <Close />
      </IconButton> */}
      {(outletData?.isPrimaryOutlet || uploadImg) && <input
        accept="image/*"
        type="file"
        id="img"
        hidden
        ref={(el) => (fileRef = el)}
        onChange={onChange}
      />}
      <label htmlFor="img">
        <img
          src={img || defaultImg || Image}
          style={{
            height: "100px",
            width: "100px",
            cursor: "pointer",
            objectFit: "contain",
            ...sx
          }}
        />
      </label>
      <Title>{file ? file.name : "Upload Image"}</Title>
      <Lead fontSize={12}>JPG, GIF or PNG. Max size of  5MB</Lead>
    </Stack>
  );
};

export default UpliadImageFieldWithoutFormik;
