import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import CustomSearchTextField from "../../components/formUI/CustomSearchTextField";
import PageHeader from "../../components/pageUI/PageHeader";
import { useSelector } from "react-redux";
import axios from "axios";
import {useSmallScreen} from "../../utils/mediaQueries"

const Notify = () => {
  const isSmallScreen = useSmallScreen();
  const [tabValue, setTabValue] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const outletId1 = useSelector((state) => state.auth.user.messId );
  const outletId2 = useSelector((state) => state.auth.user.outletId);
  const outletId3 = useSelector((state) => state.outledId.outletId);

  const messOutletId = useSelector((state) => state.duplicateOutletId.checkIsMess);
  const outletId = messOutletId ? outletId1 : outletId3 || outletId2;
  const outletName = useSelector((state) => state.auth.user.outletName);
  const [loading, setLoading] = useState(false);
  const [selectedApps, setSelectedApps] = useState(["com.mealpe"]);

  useEffect(() => {
    fetchCustomers();
  }, [outletId]);

  useEffect(() => {
    const filtered = customers?.filter((customer) =>
      customer?.customerName
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase())
    );
    setFilteredCustomers(filtered);
    setPage(0);
  }, [customers, searchQuery]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}customer/getCustomerList/${outletId}`
      );
      if (response?.data?.success) {
        setCustomers(response?.data?.data);
        setFilteredCustomers(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCustomers(
        filteredCustomers.map((customer) => customer.customerAuthUID)
      );
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelectCustomer = (customerAuthUID) => {
    setSelectedCustomers((prev) =>
      prev.includes(customerAuthUID)
        ? prev.filter((id) => id !== customerAuthUID)
        : [...prev, customerAuthUID]
    );
  };

  const handleSendNotification = async () => {
    setLoading(true);
    console.log("notify ",{
      title,
      body: description,
      selectedCustomers,
      outletName,
    })
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/sendNotifications`,
        {
          title,
          body: description,
          selectedCustomers,
          outletName,
          selectedApps
        }
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setTitle("");
        setDescription("");
        setSelectedCustomers([]);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTransaction = async () => {
    setLoading(true);
    console.log("transcation ",{
      amount,
      description,
      selectedCustomers,
      outletName,
      isVendor:true,
      outletId
    })
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/sendTransaction`,
        {
          amount,
          description,
          selectedCustomers,
          outletName,
          isVendor:true,
          outletId
        }
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setAmount("");
        setSelectedCustomers([]);
      }
    } catch (error) {
      console.error("Error processing transaction:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  return (
    <Box>
      <PageHeader
        secondary
        title="Notifications"
        subTitle="Manage notifications for your customers"
      />
      {/* <Tabs
        value={tabValue}
        onChange={(e, newValue) => {
          setTabValue(newValue);
          setDescription("");
          setSelectedCustomers([]);
        }}
        aria-label="Notification and Reward Tabs"
      >
        <Tab label="Notify" />
        <Tab label="Reward" />
      </Tabs> */}

      <Box>
        {tabValue === 0 && (
          <>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
          </>
        )}
        {tabValue === 1 && (
          <>
            <TextField
              label="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
          </>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CustomSearchTextField
            placeholder="Search customers"
            value={searchQuery}
            onChange={handleSearchChange}
          />{" "}
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            {tabValue === 0 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendNotification}
                disabled={
                  selectedCustomers.length === 0 ||
                  !title.trim() ||
                  !description.trim()
                }
                sx={{ mt: 2, textTransform: "none" }}
              >
                {loading ? "Sending..." : "Send Notification"}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleTransaction}
                disabled={
                  selectedCustomers.length === 0 ||
                  !amount.trim() ||
                  !description.trim()
                }
                sx={{ mt: 2, textTransform: "none" }}
              >
                {loading ? "Processing..." : "Process Transaction"}
              </Button>
            )}
          </Box>
        </div>
        <h5 className="mb-4">
          {selectedCustomers?.length > 0 &&
            `${selectedCustomers?.length} Selected`}{" "}
        </h5>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedCustomers.length > 0 &&
                      selectedCustomers.length < filteredCustomers.length
                    }
                    checked={
                      filteredCustomers.length > 0 &&
                      selectedCustomers.length === filteredCustomers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Customer Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Customer Mobile
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer) => (
                  <TableRow key={customer.customerAuthUID}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomers.includes(
                          customer.customerAuthUID
                        )}
                        onChange={() =>
                          handleSelectCustomer(customer.customerAuthUID)
                        }
                      />
                    </TableCell>
                    <TableCell>{customer.customerName}</TableCell>
                    <TableCell>{customer.mobile}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredCustomers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Notify;
