import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import Text from "../../../components/typographyUI/Text";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomDataGrid from "../../../components/pageUI/CustomDataGrid";
import { useGetApis } from "../../../hooks/useGetApis";
import { useSelector } from "react-redux";
import CustomDialog from "../../../components/layoutUI/CustomDialog";
import { Add, Close } from "@mui/icons-material";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import { Field, Form, Formik } from "formik";
import CustomRadiobox from "../../../components/CustomRadiobox";
import axios from "axios";
import { toast } from "react-toastify";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import {
  dismissLoader,
  presentLoader,
} from "../../../Redux Store/loaderService";
import CustomButton from "../../../components/formUI/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomSearchTextField from "../../../components/formUI/CustomSearchTextField";
import { useSmallScreen, useMediumScreen } from "../../../utils/mediaQueries";

const AddonVariations = () => {
  const {
    getCategoriesWithItems,
    getVariationsForOutlet,
    deleteVariation,
    addVariation,
  } = useGetApis();
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [itemsWithCategory, setItemsWithCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsGroup, setSelectedItemsGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    open && getItemsWithCategory();
  }, [open]);

  useEffect(() => {
    openGroup && getItemsWithCategory();
  }, [openGroup]);

  const getItemsWithCategory = async () => {
    setLoading(true);
    try {
      const items = await axios.get(`${getCategoriesWithItems}${outletIdNew}`);

      // console.log("Get Category with items ", items?.data?.data);
      if (items?.data?.success) {
        setItemsWithCategory(items?.data?.data);
      }
    } catch (error) {
      console.log("Error while items api call");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event, itemid) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemid]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemid));
    }
  };

  const handleCheckboxChangeforgroup = (event, variationId) => {
    if (event.target.checked) {
      setSelectedVariations([...selectedVariations, variationId]);
    } else {
      setSelectedVariations(
        selectedVariations.filter((id) => id !== variationId)
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "itemname",
        headerName: "Variation Name",
        flex: 1,
        minWidth: 250,
        sortable: false,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {row?.name}
          </Text>
        ),
      },
      {
        field: "price",
        headerName: "Variation Price",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => <Text bold>₹ {row?.price?.toFixed(2)}</Text>,
      },
      {
        field: "attribute",
        headerName: "Attribute",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>
            {" "}
            {row?.attributeId == 1
              ? "veg"
              : row?.attributeId == 2
              ? "non-veg"
              : "egg"}
          </Text>
        ),
      },
      {
        field: "isDefault",
        headerName: "Default variation",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.isDefault ? "Yes" : "No"}</Text>
        ),
      },
      {
        field: "status",
        headerName: "Available",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.status ? "Available" : "Not Available"}</Text>
        ),
      },
    ],
    []
  );

  const getFilterData = (data) => {
    let _data = data;
    if (search)
      _data = _data?.filter((row) =>
        row?.name.toLowerCase()?.includes(search?.toLowerCase())
      );
    return _data;
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box sx={{ width: "100%" }}>
          <PageHeader
            secondary
            title={`Variations`}
            subTitle={"View and update your store details"}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: `${isSmallScreen ? "flex-start" : "flex-end"}`,
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            <CustomSearchTextField
              placeholder="Search Variation"
              value={search}
              style={{ marginRight: "1rem" }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <CustomButton
              startIcon={<Add />}
              style={{
                marginTop: `${isSmallScreen ? "20px" : "0px"}`,
                width: isSmallScreen ? "50%" : "auto",
              }}
              onClick={() => setOpen(true)}
            >
              Add Variation
            </CustomButton>
          </Box>

          <CustomDataGrid
            rowId="variationId"
            name="variation-table"
            labelName="Variation"
            height={"700px"}
            columns={columns}
            deleteOption
            deleteUrl={`${deleteVariation}`}
            deleteItem
            url={`${getVariationsForOutlet}` + `${outletIdNew}`}
            noEditAction
            menuOnClick={({ id, label }, row) => {
              if (label === "Edit") {
                // console.log("row with edit id ",row);
                navigate("/menu/variation/" + row?.variationId);
              }
            }}
            filterFn={getFilterData}
          />

          {/* Variations */}

          {open && (
            <CustomDialog dontClose open={true} sx={{ width: "80rem" }}>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <PageHeader secondary title={`Add Variation`} />
                <Close
                  style={{ cursor: "pointer", fontSize: "2rem" }}
                  onClick={() => setOpen(false)}
                />
              </Box>

              <Formik
                initialValues={{
                  name: "",
                  price: "",
                  attributeId: "",
                  status: true,
                  isDefault: false,
                  outletId: outletIdNew,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Variation name required";
                  }
                  if (!values.price) {
                    errors.price = "Variation price required";
                  }
                  if (!values.attributeId) {
                    errors.attributeId = "attributeid required";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // console.log("res ", {
                  //   ...values,
                  //   itemArray: selectedItems,
                  // });

                  try {
                    const res = await axios.post(`${addVariation}`, {
                      ...values,
                      itemArray: selectedItems,
                    });
                    // console.log("API response:", res);
                    setOpen(false);
                    if (res?.data?.success) {
                      toast.success("Variation Added Successfully");
                      window.location.reload(); // Refresh the page
                    }
                  } catch (error) {
                    console.error("API error:", error);
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <Stack
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <CustomTextFieldFormik
                        label="Variation Name"
                        placeholder="Enter Variation Name"
                        required
                        name="name"
                      />
                      <CustomTextFieldFormik
                        label="Variation Price"
                        placeholder="Enter Variation Price"
                        required
                        name="price"
                      />
                      <Field name="attributeId">
                        {({ field }) => (
                          <CustomRadiobox
                            {...field}
                            label="Food Type"
                            options={
                              [
                                { attributeId: 1, attribute: "veg" },
                                { attributeId: 2, attribute: "non-veg" },
                                { attributeId: 3, attribute: "egg" },
                              ]?.map((a) => ({
                                ...a,
                                value: a.attributeId,
                                label: a.attribute,
                              })) || []
                            }
                            value={field.value}
                            onChange={(event) =>
                              setFieldValue("attributeId", event.target.value)
                            }
                          />
                        )}
                      </Field>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.status}
                            onChange={(event) =>
                              setFieldValue("status", event.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label={values.status ? "Available" : "Unavailable"}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isDefault}
                            onChange={(event) =>
                              setFieldValue("isDefault", event.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label={"Default Variation"}
                      />{" "}
                      <Divider />
                      <Text
                        bold
                        style={{ fontSize: "1.5rem", marginTop: "1rem" }}
                      >
                        Items
                      </Text>
                      {loading ? (
                        <Text style={{ textAlign: "center" }}>
                          Fetching items...
                        </Text>
                      ) : (
                        itemsWithCategory?.map((category, index) => (
                          <Accordion
                            key={index}
                            style={{ borderRadius: "1rem" }}
                          >
                            <AccordionSummary
                              expandIcon={<GridExpandMoreIcon />}
                            >
                              <Typography>{category.categoryname}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {category.items.map((item, idx) => (
                                <FormControlLabel
                                  key={idx}
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheckboxChange(e, item.itemid)
                                      }
                                    />
                                  }
                                  label={item.itemname}
                                />
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        ))
                      )}
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "1rem",
                        }}
                      >
                        <CustomButton
                          variant="outlined"
                          onClick={() => setOpen(false)}
                          style={{
                            height: "35px",
                            width: "5rem",
                          }}
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          disabled={isSubmitting}
                          color="secondary"
                          style={{
                            height: "35px",
                            width: "5rem",
                          }}
                        >
                          Submit
                        </CustomButton>
                      </Box>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </CustomDialog>
          )}
        </Box>
      </motion.div>
    </>
  );
};

export default AddonVariations;
