import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import BrandPng from "../../../assets/images/newLogo.png";
import DashboardSvg from "../../svgComponents/DashboardSvg";
import Title from "../../typographyUI/Title";
import OrdersSvg from "../../svgComponents/OrdersSvg";
import MenuSvg from "../../svgComponents/MenuSvg";
import SettlementsSvg from "../../svgComponents/SettlementsSvg";
import CustomersSvg from "../../svgComponents/CustomersSvg";
import OutletsSvg from "../../svgComponents/OutletsSvg";
import GrowthSvg from "../../svgComponents/GrowthSvg";
import NewBatchSvg from "../../svgComponents/NewBatchSvg";
import HiSvg from "../../svgComponents/HiSvg";
import MessSvg from "../../svgComponents/MessSvg";
import HelpSvg from "../../svgComponents/HelpSvg";
import SettingsSvg from "../../svgComponents/SettingsSvg";
import LogoutSvg from "../../svgComponents/LogoutSvg";
import KotSvg from "../../svgComponents/KotSvg";
import Text from "../../typographyUI/Text";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux Store/Slices/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import CashAndCarry from "../../svgComponents/CashAndCarry";
import { setCheckIsMess } from "../../../Redux Store/Slices/duplicateOutlet";
import NotifySvg from "../../svgComponents/NotifySvg";
import ReportSvg from "../../svgComponents/ReportSvg";
import MessMenuSvg from "../../svgComponents/MessMenuSvg";
import axios from "axios";

const SideNavbar = ({ sideBarHandler }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outletData = useSelector((state) => state.auth.user);
    const outletIdNew = useSelector(
      (state) => state.auth.user.messId || state.outledId.outletId
    );
  const checkIsMess = useSelector(
    (state) => state.duplicateOutletId.checkIsMess
  );
  const [messConfig, setIsMessConfig] = useState(false);

  // useEffect(() => {
  //   outletIdNew && getMessConfig();
  // },[]);

  // const getMessConfig = async() => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}mess/getMessConfig/${outletIdNew}`);
  //     if(response?.data?.success){
  //       setIsMessConfig(response?.data?.data);
  //     }
  //   } catch (error) {
  //     console.log("error ",error);
  //   }

  // }

  const matches = useMediaQuery("(max-width: 1200px)");

  // const [data] = useState(
  //   !outletData?.hasMess
  //     ? [
  //         {
  //           id: 1,
  //           icon: DashboardSvg,
  //           name: "Dashboard",
  //           link: "/",
  //         },
  //         {
  //           id: 2,
  //           icon: OrdersSvg,
  //           name: "Orders",
  //           link: "/orders/",
  //         },
  //         {
  //           id: 3,
  //           icon: MenuSvg,
  //           name: "Menu",
  //           children: [
  //             { id: 1, name: "Categories", link: "/menu/categories" },
  //             { id: 2, name: "Sub-Categories", link: "/menu/subcategories" },
  //             { id: 3, name: "Items", link: "/menu/items" },
  //             { id: 4, name: "Variations", link: "/menu/variation" },
  //             { id: 5, name: "Addons", link: "/menu/addon" },
  //             // { id: 4, name: "Taxation", link: "/menu/taxation" },
  //           ],
  //         },
  //         {
  //           id: 4,
  //           icon: SettlementsSvg,
  //           name: "Settlements",
  //           link: "/settlements",
  //         },

  //         {
  //           id: 5,
  //           icon: CustomersSvg,
  //           name: "Customers",
  //           link: "/customers",
  //         },
  //         {
  //           id: 6,
  //           icon: KotSvg,
  //           name: "KOT",
  //           link: "/kot",
  //         },
  //         {
  //           id: 7,
  //           icon: OutletsSvg,
  //           name: `Outlets`,
  //           link: "/outlets",
  //         },
  //         outletData?.messId &&    {
  //           id: 3,
  //           icon: MessSvg,
  //           name: `Mess Manager`,
  //           children: [

  //             // { id: 4, name: "Taxation", link: "/menu/taxation" },
  //             {
  //               id: 1,
  //               // icon: DashboardSvg,
  //               name: "Dashboard",
  //               link: "/mdashboard",
  //             },
  //             {
  //               id: 2,
  //               // icon: OrdersSvg,
  //               name: "Mess Menu",
  //               link: "/messmenu/",
  //             },
  //             {
  //               id: 3,
  //               // icon: CustomersSvg,
  //               name: "Students",
  //               link: "/messstudents",
  //             },
  //             outletData?.cashAndCarry && {
  //               id: 3,
  //               // icon: CashAndCarry,
  //               name: "Cash and Carry",
  //               link: "/cashandcarry",
  //             },
  //             {
  //               id: 4,
  //               // icon: OutletsSvg,
  //               name: "Datewise Report",
  //               link: "/rsvpanalysis",
  //             },
  //             // {
  //             //   id: 5,
  //             //   // icon: SettingsSvg,
  //             //   name: "Settings",
  //             //   link: "/settings#general",
  //             // },
  //           ]?.filter(Boolean),
  //         },
  //         {
  //           id: 8,
  //           icon: HelpSvg,
  //           name: "Help",
  //         },
  //         {
  //           id: 9,
  //           icon: SettingsSvg,
  //           name: "Settings",
  //           link: "/settings#general",
  //         },
  //         {
  //           id: 10,
  //           icon: LogoutSvg,
  //           name: "Logout",
  //           onClick: () => {
  //             dispatch(logout());
  //             navigate("/login");
  //           },
  //         },
  //       ]?.filter(Boolean)
  //     :  [
  //         {
  //           id: 1,
  //           icon: DashboardSvg,
  //           name: "Dashboard",
  //           link: "/",
  //         },
  //         {
  //           id: 2,
  //           icon: OrdersSvg,
  //           name: "Mess Menu",
  //           link: "/messmenu/",
  //         },
  //         {
  //           id: 3,
  //           icon: CustomersSvg,
  //           name: "Students",
  //           link: "/messstudents",
  //         },
  //         // outletData?.cashAndCarry && {
  //         //   id: 3,
  //         //   icon: CashAndCarry,
  //         //   name: "Cash and Carry",
  //         //   link: "/cashandcarry",
  //         // },
  //         {
  //           id: 4,
  //           icon: OutletsSvg,
  //           name: "Datewise Report",
  //           link: "/rsvpanalysis",
  //         },
  //         {
  //           id: 5,
  //           icon: SettingsSvg,
  //           name: "Settings",
  //           link: "/settings#general",
  //         },
  //         {
  //           id: 6,
  //           icon: LogoutSvg,
  //           name: "Logout",
  //           onClick: () => {
  //             dispatch(logout());
  //             navigate("/login");
  //           },
  //         },
  //       ]?.filter(Boolean)

  // );
  const data = useMemo(() => {
    return checkIsMess
      ? [
          {
            id: 1,
            icon: DashboardSvg,
            name: "Dashboard",
            link: "/mdashboard",
          },
          {
            id: 2,
            icon: OrdersSvg,
            name: "Mess Menu",
            link: "/messmenu",
          },
          {
            id: 3,
            icon: MessMenuSvg,
            name: "Orders",
            link: "/messOrders",
          },
          {
            id: 4,
            icon: CustomersSvg,
            name: "Users",
            link: "/messusers",
          },
          {
            id: 5,
            icon: ReportSvg,
            name: "Datewise Report",
            children: [
              { id: 1, name: "Meal Reports", link: "/rsvpanalysis" },
              { id: 2, name: "Rating Dashboard", link: "/ratingsdashboard" },
              { id: 3, name: "Feedbacks", link: "/feedbacks" },
            ],
          },
          {
            id: 6,
            icon: NotifySvg,
            name: "Notifications",
            link: "/notify",
          },

          // {
          //   id: 5,
          //   icon: SettingsSvg,
          //   name: 'Settings',
          //   link: '/settings#general',
          // },
          {
            id: 7,
            icon: LogoutSvg,
            name: "Logout",
            onClick: () => {
              dispatch(logout());
              dispatch(setCheckIsMess(false));
              navigate("/login");
            },
          },
        ].filter(Boolean)
      : !outletData?.hasMess
      ? [
          {
            id: 1,
            icon: DashboardSvg,
            name: "Dashboard",
            link: "/",
          },
          {
            id: 2,
            icon: OrdersSvg,
            name: "Orders",
            link: "/orders/",
          },
          {
            id: 3,
            icon: MenuSvg,
            name: "Menu",
            children: [
              { id: 1, name: "Categories", link: "/menu/categories" },
              { id: 2, name: "Sub-Categories", link: "/menu/subcategories" },
              { id: 3, name: "Items", link: "/menu/items" },
              { id: 4, name: "Variations", link: "/menu/variation" },
              { id: 5, name: "Addons", link: "/menu/addon" },
            ],
          },
          {
            id: 4,
            icon: SettlementsSvg,
            name: "Settlements",
            link: "/settlements",
          },
          {
            id: 5,
            icon: CustomersSvg,
            name: "Customers",
            link: "/customers",
          },
          {
            id: 6,
            icon: KotSvg,
            name: "KOT",
            link: "/kot",
          },
          {
            id: 7,
            icon: OutletsSvg,
            name: "Outlets",
            link: "/outlets",
          },
          {
            id: 7,
            icon: NotifySvg,
            name: "Notifications",
            link: "/notify",
          },
          {
            id: 8,
            icon: HelpSvg,
            name: "Help",
          },
          {
            id: 9,
            icon: SettingsSvg,
            name: "Settings",
            link: "/settings#general",
          },
          {
            id: 10,
            icon: LogoutSvg,
            name: "Logout",
            onClick: () => {
              dispatch(logout());
              dispatch(setCheckIsMess(false));
              navigate("/login");
            },
          },
        ].filter(Boolean)
      : [
          {
            id: 1,
            icon: DashboardSvg,
            name: "Dashboard",
            link: "/",
          },
          {
            id: 2,
            icon: OrdersSvg,
            name: "Mess Menu",
            link: "/messmenu",
          },
          {
            id: 3,
            icon: MessMenuSvg,
            name: "Orders",
            link: "/messOrders",
          },
          {
            id: 4,
            icon: CustomersSvg,
            name: "Users",
            link: "/messusers",
          },
          {
            id: 5,
            icon: ReportSvg,
            name: "Datewise Report",
            children: [
              { id: 1, name: "Meal Reports", link: "/rsvpanalysis" },
              { id: 2, name: "Rating Dashboard", link: "/ratingsdashboard" },
              { id: 3, name: "Feedbacks", link: "/feedbacks" },
            ],
          },
          {
            id: 6,
            icon: OutletsSvg,
            name: "Outlets",
            link: "/outlets",
          },
          {
            id: 7,
            icon: NotifySvg,
            name: "Notifications",
            link: "/notify",
          },
          {
            id: 8,
            icon: SettingsSvg,
            name: "Settings",
            link: "/settings#general",
          },
          {
            id: 9,
            icon: LogoutSvg,
            name: "Logout",
            onClick: () => {
              dispatch(logout());
              dispatch(setCheckIsMess(false));
              navigate("/login");
            },
          },
        ].filter(Boolean);
  }, [outletData, checkIsMess, dispatch, navigate]);

  return (
    <Stack
      sx={{
        // height: "100vh",
        // position: "fixed",
        // top: 0,
        // left: 0,
        // width: "60rem",
        flex: matches ? "1" : "0 0 20rem",
        borderRight: "1px #E2E8F0 solid",
        height: "100%",
        display: location.pathname == "/outlets/onboard" ? "none" : "flex",
        // display:'none'
      }}
    >
      {/* <img
        src={BrandPng}
        style={{
          width: "1.5rem",
          height: "2.3rem",
          objectFit: "contain",
          marginBottom: "1.14rem",
          marginTop: "1.14rem",
          marginLeft: "1.72rem",
        }}
      /> */}
      <Stack
        px={"0.9rem"}
        py={"10px"}
        gap="6px"
        sx={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {data
          .filter((f) => {
            const isAuthId = !!outletData?.outletStaffData?.outletStaffAuthUId,
              isIncluded =
                outletData?.outletStaffData?.roleId?.access?.includes(f.name);
            if (
              f.name === "Outlets" &&
              !outletData?.outletStaffData?.outletStaffAuthUId
            ) {
              if (!outletData?.isPrimaryOutlet) {
                return false;
              } else {
                return true;
              }
            } else if (isAuthId) {
              if (isIncluded || f.name == "Logout" || f.name == "Help") {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .map((item) =>
            item.link ? (
              <Link
                to={item.link}
                onClick={sideBarHandler}
                key={item.id}
                style={{ textDecoration: "none" }}
              >
                <SideNavbarItem
                  key={item.id}
                  {...item}
                  sx={{
                    marginTop: item.id == 8 ? "auto" : 0,
                  }}
                />
              </Link>
            ) : item.children ? (
              <Accordion
                key={item.id}
                sx={{
                  boxShadow: "none",
                  "&.Mui-expanded": {
                    m: 0,
                    "& svg + p": {
                      color: "red",
                      fontWeight: "600",
                      color: "#6366F1",
                    },
                    "& svg": {
                      fill: "#6366F1",
                    },
                  },
                  "& .MuiAccordionSummary-content": {
                    m: 0,
                    p: 0,
                    "&.Mui-expanded": { m: 0 },
                  },
                  "& .MuiAccordionSummary-root": {
                    p: 0,
                    minHeight: 0,
                  },
                  "&:before": {
                    backgroundColor: "none",
                    position: "relative",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <SideNavbarItem
                    key={item.id}
                    onClick={item.onClick}
                    {...item}
                    sx={{
                      marginTop: item.id == 8 ? "auto" : 0,
                      flex: 1,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {item.children.map((item) => (
                    <Link
                      onClick={sideBarHandler}
                      to={item.link}
                      key={item.id}
                      style={{ textDecoration: "none" }}
                    >
                      <SideNavbarItem
                        key={item.id}
                        {...item}
                        sx={{
                          marginTop: item.id == 8 ? "auto" : 0,
                          mb: 1,
                        }}
                      />
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <SideNavbarItem
                key={item.id}
                onClick={item.onClick}
                {...item}
                sx={{
                  marginTop: item.id == 8 ? "auto" : 0,
                }}
              />
            )
          )}
      </Stack>
    </Stack>
  );
};

export default SideNavbar;

const SideNavbarItem = ({ icon: Icon, name, sx, link, onClick }) => {
  const location = useLocation();
  const isCurrentPage = location.pathname == link;
  return (
    <Box
      onClick={() => onClick?.()}
      display="flex"
      px={"0.9rem"}
      py="8px"
      gap={"0.9rem"}
      alignItems={"center"}
      sx={{
        transition: "0.3s",

        cursor: "pointer",
        borderRadius: "8px",
        ...sx,
        "&:hover": {
          backgroundColor: "#EEF2FF",
          "& p": {
            fontWeight: "600",
            // transition: "0.3s",
          },
        },
        backgroundColor: isCurrentPage && "#EEF2FF",
        "& p": {
          fontWeight: isCurrentPage ? "600" : "400",
          color: isCurrentPage && "#6366F1",
        },
      }}
    >
      {Icon && <Icon color={isCurrentPage ? "#6366F1" : "#64748B"} />}
      <Text
        color={"#64748B"}
        fontWeight={500}
        sx={{
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        {name}
      </Text>
    </Box>
  );
};
