import { Box } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import PageHeader from "../components/pageUI/PageHeader";
import PaperBox from "../components/layoutUI/PaperBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  FormControlLabel,
  Stack,
  Checkbox,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Formik, Form, ErrorMessage } from "formik";
import CustomTextFieldFormik from "../components/formUI/CustomTextFieldFormik";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Text from "../components/typographyUI/Text";
import { useGetApis } from "../hooks/useGetApis";
import CustomButton from "../components/formUI/CustomButton";
import Img from "../assets/icons/Image.png";
import CustomCheckboxWithFormik from "../components/formUI/CustomCheckboxWithFormik";

const WeekCheckbox = ({ week, values, setFieldValue }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={
          Array.isArray(values.selectedWeeks) &&
          values.selectedWeeks.includes(week)
        }
        onChange={(event) => {
          const selectedWeeks = Array.isArray(values.selectedWeeks)
            ? [...values.selectedWeeks]
            : [];
          if (event.target.checked) {
            selectedWeeks.push(week);
          } else {
            const index = selectedWeeks.indexOf(week);
            if (index !== -1) {
              selectedWeeks.splice(index, 1);
            }
          }
          setFieldValue("selectedWeeks", selectedWeeks);
        }}
        name={week}
      />
    }
    label={`Week ${week.charAt(week.length - 1)}`}
  />
);

const EditMessMenu = () => {
  const outletIdNew = useSelector((state) => state.auth.user.messId || state.outledId.outletId);
  const navigate = useNavigate();
  const [mealData, setMealData] = useState([]);
  const { menuid1, menuid2, menuid3, menuid4 } = useParams();

  const { getSingleMenu, addWeeklyMenu } = useGetApis();

  const weeks = ["1", "2", "3", "4"];

  const { data: data1 } = useQuery(["menu", menuid1], async () => {
    const response = await fetch(`${getSingleMenu}${menuid1}`);

    return response.json();
  });

  const { data: data2 } = useQuery(["menu", menuid2], async () => {
    const response = await fetch(`${getSingleMenu}${menuid2}`);
    return response.json();
  });

  const { data: data3 } = useQuery(["menu", menuid3], async () => {
    const response = await fetch(`${getSingleMenu}${menuid3}`);
    return response.json();
  });

  const { data: data4 } = useQuery(["menu", menuid4], async () => {
    const response = await fetch(`${getSingleMenu}${menuid4}`);
    return response.json();
  });

  // console.log({data1,data2,data3,data4});

  useEffect(() => {
    outletIdNew && getMealData();
  }, [outletIdNew]);

  const getMealData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/weeklyMenu/getEnabledMeals/${outletIdNew}`
      );
      console.log("get menu res ", result);
      setMealData(result?.data?.data);
    } catch (error) {
      console.log("Error while fetching menu data");
    }
  };

  if (!data1 || !data2 || !data3 || !data4) {
    return <Text>Loading...</Text>;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <KeyboardBackspaceIcon
          sx={{ cursor: "pointer", marginTop: "0.3rem", fontSize: "2rem" }}
          onClick={() => navigate("/messmenu")}
        />
        <PageHeader secondary title={"Edit Menu"} />
      </Box>

      <PaperBox
        shadowed
        padding={"0 24px"}
        sx={{
          flex: "1",
          overflow: "auto",
          height: "72vh",
          maxWidth: "1250px",
          margin: "auto",
        }}
      >
        <Box id="menu-media" sx={{ pt: "24px", pb: 3 }}>
          <PageHeader secondary title={"Menu"} />
          <Formik
            initialValues={{
              dayOfWeek: data1?.data?.dayOfWeek,
              breakfast: data1?.data?.menuDescription || "",
              breakfastPrice: data1?.data?.price || null,
              lunch: data2?.data?.menuDescription || "",
              lunchPrice: data2?.data?.price || null,
              dinner: data4?.data?.menuDescription || "",
              dinnerPrice: data4?.data?.price || null,
              highTea: data3?.data?.menuDescription || "",
              highTeaPrice: data3?.data?.price || null,
              selectedWeeks: data1?.data?.weekNumber
                ? [String(data1?.data?.weekNumber)]
                : [],
              outletId: outletIdNew,
              breakfastImage: data1?.data?.breakfastImage || "",
              lunchImage: data2?.data?.lunchImage || "",
              dinnerImage: data4?.data?.dinnerImage || "",
              highTeaImage: data3?.data?.highTeaImage || "",
              isNotify: false,
              notificationTitle: "",
              notificationDescription: "",
            }}
            validate={(values) => {
              const errors = {};

              if (mealData?.includes(1) && !values.breakfast) {
                errors.breakfast = "Breakfast is required";
              }

              if (mealData?.includes(2) && !values.lunch) {
                errors.lunch = "Lunch is required";
              }

              if (mealData?.includes(3) && !values.dinner) {
                errors.dinner = "Dinner is required";
              }

              if (mealData?.includes(4) && !values.highTea) {
                errors.highTea = "High Tea is required";
              }

              if (values.selectedWeeks.length === 0) {
                errors.selectedWeeks = "At least one week must be selected";
              }
              // Price validations
              const validatePrice = (price, fieldName) => {
                if (price !== "") {
                  const numPrice = Number(price);
                  if (isNaN(numPrice)) {
                    errors[fieldName] = "Must be a valid number";
                  } else if (numPrice < 0) {
                    errors[fieldName] = "Price cannot be negative";
                  }
                }
              };

              validatePrice(values.breakfastPrice, "breakfastPrice");
              validatePrice(values.lunchPrice, "lunchPrice");
              validatePrice(values.dinnerPrice, "dinnerPrice");
              validatePrice(values.highTeaPrice, "highTeaPrice");

              const validateImage = (image, fieldName) => {
                if (image instanceof File) {
                  if (!image.type.match(/image\/(jpeg|jpg|png)/)) {
                    errors[fieldName] = "Only JPG, JPEG, PNG files are allowed";
                  } else if (image.size > 5 * 1024 * 1024) {
                    errors[fieldName] = "Image size should be less than 5MB";
                  }
                }
                // Don't validate if it's a string (existing image URL) or empty
              };
              validateImage(values.breakfastImage, "breakfastImage");
              validateImage(values.lunchImage, "lunchImage");
              validateImage(values.dinnerImage, "dinnerImage");
              validateImage(values.highTeaImage, "highTeaImage");

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // console.log("menu ", values);
              setSubmitting(true);
              const formData = new FormData();
              // Object.keys(values).forEach((key) => {
              //   if (values[key] instanceof File) {
              //     formData.append(key, values[key]);
              //   } else {
              //     formData.append(key, values[key]);
              //   }
              // });
              Object.keys(values).forEach((key) => {
                if (Array.isArray(values[key])) {
                  // If the value is an array, serialize it to a JSON string
                  formData.append(key, JSON.stringify(values[key]));
                } else if (values[key] instanceof File) {
                  // If the value is a file, append it as is
                  formData.append(key, values[key]);
                } else {
                  // For all other types, append the value as is
                  formData.append(key, values[key]);
                }
              });

              try {
                const result = await axios.post(`${addWeeklyMenu}`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                // console.log("menu ", result);

                if (result?.data?.success) {
                  setSubmitting(false);
                  resetForm();

                  toast.success("Menu Updated Successfully");
                  navigate("/messmenu");
                  // window.location.reload();
                }
              } catch {
                console.log("Error while creating menu");
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >{console.log("mealData",mealData)}
                  {mealData?.includes(1) && (
                    <>
                      <h2>Breakfast</h2>
                      <Box
                        sx={{
                          p: 2,
                          width: "25%",
                          cursor: "pointer",
                          border: "1px dashed #000",
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="breakfastImage"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue("breakfastImage", file);
                          }}
                        />
                        <label htmlFor="breakfastImage">
                          <IconButton component="span">
                            {/* <AddPhotoAlternateIcon /> */}
                            <img
                              src={Img}
                              alt="img"
                              style={{ height: "5rem", width: "5rem" }}
                            />
                          </IconButton>
                          <Typography>Breakfast Image</Typography>
                        </label>
                        {values?.breakfastImage && (
                          <Box sx={{ mt: 2 }}>
                            <img
                              src={
                                values[`breakfastImage`] instanceof File
                                  ? URL.createObjectURL(
                                      values[`breakfastImage`]
                                    )
                                  : values[`breakfastImage`]
                              }
                              alt="Breakfast preview"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </Box>
                        )}
                      </Box>
                      <CustomTextFieldFormik
                        label="Breakfast"
                        placeholder="Enter Breakfast"
                        required
                        name="breakfast"
                      />
                      <CustomTextFieldFormik
                        label="Breakfast Price"
                        placeholder="Enter Breakfast Price"
                        type="number"
                        name="breakfastPrice"
                      />
                    </>
                  )}
                  {mealData?.includes(2) && (
                    <>
                      <h2>Lunch</h2>
                      <Box
                        sx={{
                          p: 2,
                          width: "25%",
                          cursor: "pointer",
                          border: "1px dashed #000",
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="lunchImage"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue("lunchImage", file);
                          }}
                        />
                        <label htmlFor="lunchImage">
                          <IconButton component="span">
                            {/* <AddPhotoAlternateIcon /> */}
                            <img
                              src={Img}
                              alt="img"
                              style={{ height: "5rem", width: "5rem" }}
                            />
                          </IconButton>
                          <Typography>Lunch Image</Typography>
                        </label>
                        {values?.lunchImage && (
                          <Box sx={{ mt: 2 }}>
                            <img
                              src={
                                values[`lunchImage`] instanceof File
                                  ? URL.createObjectURL(values[`lunchImage`])
                                  : values[`lunchImage`]
                              }
                              alt="Lunch preview"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </Box>
                        )}
                      </Box>
                      <CustomTextFieldFormik
                        label="Lunch"
                        placeholder="Enter Lunch"
                        required
                        name="lunch"
                      />
                      <CustomTextFieldFormik
                        label="Lunch price"
                        placeholder="Enter Lunch Price"
                        type="number"
                        name="lunchPrice"
                      />
                    </>
                  )}
                  {mealData?.includes(3) && (
                    <>
                      <h2>High Tea</h2>
                      <Box
                        sx={{
                          p: 2,
                          width: "25%",
                          cursor: "pointer",
                          border: "1px dashed #000",
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="highTeaImage"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue("highTeaImage", file);
                          }}
                        />
                        <label htmlFor="highTeaImage">
                          <IconButton component="span">
                            {/* <AddPhotoAlternateIcon /> */}
                            <img
                              src={Img}
                              alt="img"
                              style={{ height: "5rem", width: "5rem" }}
                            />
                          </IconButton>
                          <Typography>HighTea Image</Typography>
                        </label>
                        {values?.highTeaImage && (
                          <Box sx={{ mt: 2 }}>
                            <img
                              src={
                                values[`highTeaImage`] instanceof File
                                  ? URL.createObjectURL(values[`highTeaImage`])
                                  : values[`highTeaImage`]
                              }
                              alt="HighTea preview"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </Box>
                        )}
                      </Box>
                      <CustomTextFieldFormik
                        label="High Tea"
                        placeholder="Enter High Tea"
                        required
                        name="highTea"
                      />
                      <CustomTextFieldFormik
                        label="High Tea Price"
                        placeholder="Enter High Tea Price"
                        type="number"
                        name="highTeaPrice"
                      />
                    </>
                  )}
                  {mealData?.includes(4) && (
                    <>
                      <h2>Dinner</h2>
                      <Box
                        sx={{
                          p: 2,
                          width: "25%",
                          cursor: "pointer",
                          border: "1px dashed #000",
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="dinnerImage"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue("dinnerImage", file);
                          }}
                        />
                        <label htmlFor="dinnerImage">
                          <IconButton component="span">
                            {/* <AddPhotoAlternateIcon /> */}
                            <img
                              src={Img}
                              alt="img"
                              style={{ height: "5rem", width: "5rem" }}
                            />
                          </IconButton>
                          <Typography>Dinner Image</Typography>
                        </label>
                        {values?.dinnerImage && (
                          <Box sx={{ mt: 2 }}>
                            <img
                              src={
                                values[`dinnerImage`] instanceof File
                                  ? URL.createObjectURL(values[`dinnerImage`])
                                  : values[`dinnerImage`]
                              }
                              alt="Dinner preview"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </Box>
                        )}
                      </Box>
                      <CustomTextFieldFormik
                        label="Dinner"
                        placeholder="Enter Dinner"
                        required
                        name="dinner"
                      />
                      <CustomTextFieldFormik
                        label="Dinner Price"
                        placeholder="Enter Dinner Price"
                        type="number"
                        name="dinnerPrice"
                      />
                    </>
                  )}

                  <Box>
                    {weeks.map((week) => (
                      <WeekCheckbox
                        key={week}
                        week={week}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ))}
                    <ErrorMessage
                      name="selectedWeeks"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>

                  <Box>
                    <CustomCheckboxWithFormik
                      name="isNotify"
                      label="Notify Users"
                    />

                    {values.isNotify && (
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <CustomTextFieldFormik
                          label="Notification Title"
                          placeholder="Enter notification title"
                          name="notificationTitle"
                        />
                        <CustomTextFieldFormik
                          label="Notification Description"
                          placeholder="Enter notification description"
                          name="notificationDescription"
                          multiline
                          rows={4}
                        />
                      </Box>
                    )}
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <CustomButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      style={{
                        height: "35px",
                        width: "5rem",
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{
                        height: "35px",
                        width: "5rem",
                      }}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </PaperBox>
    </motion.div>
  );
};

export default EditMessMenu;
