import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Checkbox,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { motion, PresenceContext } from "framer-motion";
import PaperBox from "../../../components/layoutUI/PaperBox";
import { Formik, Form } from "formik";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import { useNavigate, useParams } from "react-router-dom";
import CustomRadiobox from "../../../components/CustomRadiobox";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Text from "../../../components/typographyUI/Text";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import {
  dismissLoader,
  presentLoader,
} from "../../../Redux Store/loaderService";
import { useGetApis } from "../../../hooks/useGetApis";
import CustomButton from "../../../components/formUI/CustomButton";
const EditAddon = () => {
  const { getAddonGroupById, getCategoriesWithItems, updateAddonGroup } =
    useGetApis();
  const [addon, setAddon] = useState(null); // Changed to null to check if data is loaded
  const [loading, setLoading] = useState(false);
  const [itemsWithCategory, setItemsWithCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVariations, setSelectedVariations] = useState([]);

  const navigate = useNavigate();
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const { addonid } = useParams();

  const getAddonGroup = async () => {
    try {
      const response = await axios.get(`${getAddonGroupById}${addonid}`);
      // console.log("Addon Api response ", response?.data?.data);

      if (response?.data?.success) {
        setAddon(response?.data?.data);
        setSelectedItems(response?.data?.data?.selectedItems);
        // console.log("selected items ", response?.data?.data?.selectedItems);
        setSelectedVariations(response?.data?.data?.selectedVariations);
        // console.log(
        //   "selected variations ",
        //   response?.data?.data?.selectedVariations
        // );
        // console.log("addon id ", response?.data?.data?.itemArray);
      }
    } catch (error) {
      console.log("Error while fetching variation by id", error);
    }
  };

  const getItemsWithCategory = async () => {
    setLoading(true);
    try {
      const items = await axios.get(`${getCategoriesWithItems}${outletIdNew}`);

      // console.log("Get Category with items ", items?.data?.data);
      if (items?.data?.success) {
        setItemsWithCategory(items?.data?.data);
      }
    } catch (error) {
      console.log("Error while items api call");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAddonGroup();
  }, [addonid]);

  useEffect(() => {
    getItemsWithCategory();
  }, [outletIdNew]);

  if (!addon) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  const handleCheckboxChange = (event, itemid) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemid]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemid));
    }
  };

  const handleCheckboxChangeforgroup = (event, variationId) => {
    if (event.target.checked) {
      setSelectedVariations([...selectedVariations, variationId]);
    } else {
      setSelectedVariations(
        selectedVariations.filter((id) => id !== variationId)
      );
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <KeyboardBackspaceIcon
          sx={{ cursor: "pointer", marginTop: "0.3rem", fontSize: "2rem" }}
          onClick={() => navigate("/menu/addon")}
        />
        <PageHeader secondary title={"Edit Addons"} />
      </Box>

      <PaperBox
        shadowed
        padding={"0 24px"}
        sx={{
          flex: "1",
          overflow: "auto",
          height: "72vh",
          maxWidth: "1250px",
          margin: "auto",
        }}
      >
        <Box id="menu-media" sx={{ pt: "24px", pb: 3 }}>
          <PageHeader secondary title={"Addons"} />
          <Formik
            initialValues={{
              name: addon?.name || "",
              rank: addon?.rank || "",
              active: addon?.active || false,
              max_selection: addon?.max_selection || "1",
              min_selection: addon?.min_selection || "0",
              outletId: outletIdNew,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Group name required";
              }
              if (!values.rank) {
                errors.rank = "Rank required";
              }
              if (values.max_selection === "" && values.max_selection !== 0) {
                errors.max_selection = "Max selection required";
              }
              if (values.min_selection === "" && values.min_selection !== 0) {
                errors.min_selection = "Min selection required";
              }
              if (values.max_selection < values.min_selection) {
                errors.max_selection =
                  "Max value must be greter then min value";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // console.log("res", {
              //   ...values,
              //   selectedItems,
              //   selectedVariations,
              // });
              presentLoader();
              try {
                const res = await axios.post(`${updateAddonGroup}${addonid}`, {
                  ...values,
                  selectedItems,
                  selectedVariations,
                });
                // console.log("API response:", res);
                if (res?.data?.success) {
                  toast.success("AddonGroup Updated Successfully");
                  navigate("/menu/addon");
                }
              } catch (error) {
                console.error("API error:", error);
              } finally {
                dismissLoader();
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                  <CustomTextFieldFormik
                    label="Addon Group Name"
                    placeholder="Enter Group Name"
                    required
                    name="name"
                  />
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                    }}
                  >
                    <CustomTextFieldFormik
                      label="Rank"
                      placeholder="Enter Rank"
                      required
                      name="rank"
                      type="number"
                    />

                    <CustomTextFieldFormik
                      label="Max Selection"
                      placeholder="Enter Number"
                      required
                      name="max_selection"
                      type="number"
                    />
                    <CustomTextFieldFormik
                      label="Min Selection"
                      placeholder="Enter Number"
                      required
                      name="min_selection"
                      type="number"
                    />
                  </Box>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.active}
                        onChange={(event) =>
                          setFieldValue("active", event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label={values.active ? "Active" : "Deactive"}
                  />

                  <Divider />

                  <Text bold style={{ fontSize: "1.5rem", marginTop: "1rem" }}>
                    Items
                  </Text>
                  {loading ? (
                    <Text style={{ textAlign: "center" }}>
                      Fetching items...
                    </Text>
                  ) : (
                    itemsWithCategory?.map((category, index) => (
                      <Accordion key={index} style={{ borderRadius: "1rem" }}>
                        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                          <Typography>{category.categoryname}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {category.items.map((item, idx) => (
                            <>
                              <FormControlLabel
                                key={idx}
                                control={
                                  <Checkbox
                                    checked={selectedItems?.includes(
                                      item.itemid
                                    )}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, item.itemid)
                                    }
                                  />
                                }
                                label={item.itemname}
                              />
                              <div style={{ marginLeft: "20px" }}>
                                {item.variations &&
                                  item.variations.map((variation, varIdx) => (
                                    <FormControlLabel
                                      key={`${idx}-${varIdx}`}
                                      control={
                                        <Checkbox
                                          checked={selectedVariations?.includes(
                                            variation.variationId
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChangeforgroup(
                                              e,
                                              variation.variationId
                                            )
                                          }
                                        />
                                      }
                                      label={variation.name}
                                    />
                                  ))}
                              </div>
                            </>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))
                  )}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <CustomButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      style={{
                        height: "35px",
                        width: "5rem",
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </PaperBox>
    </motion.div>
  );
};

export default EditAddon;
